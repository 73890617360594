<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="!isMobile()">
    <div class="header__practice-brand">
      <div class="header__practice-brand--wrapper">
        <img
          *ngIf="data.practice?.logo || data.themeConfig?.customLogo"
          [src]="data.themeConfig?.customLogo ?? data.practice?.logo"
          class="header__practice-logo"
          role="presentation"
          alt=""
        />
        <h1 class="header__practice-name" aria-label="Practice name">
          {{ data.headerName || data.groupName || data.practice?.name }}
        </h1>
      </div>
      <div *ngIf="data.themeConfig?.customNote as note" aria-label="Note">
        {{ note }}
      </div>
    </div>
    <address *ngIf="!data.groupName">
      <div
        class="header__practice-address"
        aria-label="Address"
        [class.header__practice-address--long]="
          getPracticeAddressLength(data.practice?.address) > 90
        "
      >
        {{ data.practice?.address }}
      </div>
      <div class="header__practice-contact">
        <div *ngIf="data.practice?.phone" aria-label="Phone number">
          <span class="header__phone-label">{{
            'HEADER.PHONE' | translate
          }}</span>
          <a href="tel:{{ data.practice?.phone }}">{{
            data.practice.phone | phone
          }}</a>
        </div>
        <div aria-label="Email address">
          <a href="mailto:{{ data.practice?.email }}">{{
            data.practice?.email
          }}</a>
        </div>
      </div>
    </address>
  </ng-container>
  <ng-container *ngIf="isMobile()">
    <div class="header__practice-brand">
      <img
        *ngIf="data.themeConfig?.customLogo || data.practice?.logo"
        [src]="data.themeConfig?.customLogo ?? data.practice?.logo"
        class="header__practice-logo"
        role="presentation"
        alt=""
      />
      <h1 class="header__practice-name">
        {{ data.headerName || data.groupName || data.practice?.name }}
      </h1>
    </div>
  </ng-container>
</ng-container>
