import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SpinnerService } from './common/spinner/spinner.service';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ScheduleService } from './main/schedule.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(SpinnerComponent, { static: true })
  SpinnerComponent: SpinnerComponent;
  showSpinner = false;

  readonly pageHidden$ = this.scheduleService.initialized$.pipe(
    map((initialized) => !initialized)
  );

  constructor(
    readonly spinnerService: SpinnerService,
    readonly scheduleService: ScheduleService,
    private readonly mediaMatcher: MediaMatcher,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    spinnerService.spinnerComponent = SpinnerComponent;
  }

  ngOnInit(): void {
    this.updateFavicon();
  }

  private updateFavicon(): void {
    const darkThemeMatcher = this.mediaMatcher.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    const lightSchemeIcon = this.document.querySelector(
      'link#favicon-light'
    ) as HTMLElement;
    const darkSchemeIcon = this.document.querySelector(
      'link#favicon-dark'
    ) as HTMLElement;

    if (darkThemeMatcher.matches) {
      this.renderer.removeChild(this.document.head, lightSchemeIcon);
      this.renderer.appendChild(this.document.head, darkSchemeIcon);
    } else {
      this.renderer.removeChild(this.document.head, darkSchemeIcon);
      this.renderer.appendChild(this.document.head, lightSchemeIcon);
    }
  }
}
