import {
  BrowserModule,
  DomSanitizer,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './common/common.module';
import { PracticeService } from './common/practice';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';
import { API_URL, CLIENT_ID, CLIENT_SECRET, SERVER_URL } from './app.config';
import { DeviceService } from './common/services/device.service';
import { ErrorMessageService } from './common/services/error-message.service';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpBackend,
} from '@angular/common/http';
import { ModentoClientHeaderInterceptor } from './common/interceptors/modento-client-header-interceptor.service';
import { AcceptLanguageHeaderInterceptor } from './common/interceptors/accept-language-header-interceptor.service';
import { SentryModule } from './sentry/sentry.module';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { ScheduleStepsComponent } from './main/schedule-steps/schedule-steps.component';
import {
  RecaptchaFormsModule,
  RecaptchaV3Module,
  ReCaptchaV3Service,
  RECAPTCHA_V3_SITE_KEY,
} from 'ng-recaptcha';
import { MatIconRegistry } from '@angular/material/icon';
import { ScheduleData } from './common/models/schedule-data.model';
import { SpinnerService } from './common/spinner/spinner.service';
import { MatDialogModule } from '@angular/material/dialog';
import { ApiUrlInterceptor } from './common/interceptors/api-url.interceptor';
import { PracticeGuidInterceptor } from './common/interceptors/practice-guid.interceptor';
import { PhonePipeModule } from './common/pipes/phone/phone-pipe.module';
import { StoreModule } from '@ngrx/store';
import { AppStateModule } from './state/app-state.module';
import { storeDevToolsImport } from '../imports/store-dev-tools-import';

export function createTranslateLoader(
  httpBackend: HttpBackend
): TranslateHttpLoader {
  return new TranslateHttpLoader(
    new HttpClient(httpBackend),
    './assets/i18n/',
    '.json?v=' + VERSION.hash
  );
}

const createMatIconRegistry = (
  httpBackend: HttpBackend,
  domSanitizer: DomSanitizer
): MatIconRegistry => {
  return new MatIconRegistry(
    new HttpClient(httpBackend),
    domSanitizer,
    window.document,
    {
      handleError: (): void => {},
    }
  );
};

export function appInitializerFactory(translateService: TranslateService) {
  return (): Promise<void> => {
    translateService.setDefaultLang('en');
    return translateService.use('en').toPromise();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    HeaderComponent,
    LayoutComponent,
    ScheduleStepsComponent,
  ],
  imports: [
    TranslateModule,
    AppCommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    SentryModule,
    MatDialogModule,
    PhonePipeModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
        },
      }
    ),
    AppStateModule,
    ...storeDevToolsImport,
  ],

  providers: [
    { provide: SERVER_URL, useValue: environment.serverUrl },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: CLIENT_ID, useValue: environment.clientId },
    { provide: CLIENT_SECRET, useValue: environment.clientSecret },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ModentoClientHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PracticeGuidInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [TranslateService],
    },
    {
      provide: MatIconRegistry,
      useFactory: createMatIconRegistry,
      deps: [HttpBackend, DomSanitizer],
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captchaV3Key },
    PracticeService,
    DeviceService,
    ErrorMessageService,
    ReCaptchaV3Service,
    ScheduleData,
    SpinnerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  icons: Array<{ iconName: string; url: SafeResourceUrl }> = [
    {
      iconName: 'calendar',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-calendar.svg'
      ),
    },
    {
      iconName: 'no-slots',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-calendar-no-slots.svg'
      ),
    },
    {
      iconName: 'longarrow',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-longarrow.svg'
      ),
    },
    {
      iconName: 'cancel',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-cancel.svg'
      ),
    },
    {
      iconName: 'thumb-up',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-thumb-up.svg'
      ),
    },
    {
      iconName: 'error-face',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-error-face.svg'
      ),
    },
    {
      iconName: 'phone',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-phone.svg'
      ),
    },
    {
      iconName: 'info',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-info.svg'
      ),
    },
    {
      iconName: 'wisetack',
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/icon-wisetack.svg'
      ),
    },
  ];

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.addIcons();
  }

  private addIcons(): void {
    this.icons.map(({ iconName, url }) => {
      this.matIconRegistry.addSvgIcon(iconName, url);
    });
  }
}
