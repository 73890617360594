import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Practice, PracticeService } from '../common/practice';
import { combineLatest, Observable, of } from 'rxjs';
import { BaseComponent } from '../common/base-component/base-component';
import { filter, map, switchMap } from 'rxjs/operators';
import { CustomScheduleService } from '../data-access/custom-schedule/custom-schedule.service';
import { ThemeCustomizerService } from '../common/services/theme-customizer.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent {
  location$: Observable<Partial<Practice>> = this.practiceService
    .getPractice()
    .pipe(
      filter((practice) => !!practice),
      map((practice) => ({
        name: practice.name,
        logo: practice.logo,
        address: practice.address,
        email: practice.email,
        phone: practice.phone,
      }))
    );

  groupName$: Observable<string> =
    this.practiceService.shouldShowGroupName$.pipe(
      switchMap((shouldShowGroupName) => {
        return shouldShowGroupName
          ? this.practiceService.getPracticeGroupName()
          : of(null);
      })
    );

  headerName$: Observable<string> =
    this.practiceService.shouldShowHeaderName$.pipe(
      switchMap((shouldShowHeaderName) => {
        return shouldShowHeaderName
          ? this.customScheduleService.getCustomLinkHeaderName()
          : of(null);
      })
    );

  data$ = combineLatest([
    this.location$,
    this.groupName$,
    this.headerName$,
    this.themeCustomizerService.theme$,
  ]).pipe(
    map(([practice, groupName, headerName, themeConfig]) => ({
      practice,
      groupName,
      headerName,
      themeConfig,
    }))
  );

  constructor(
    private readonly practiceService: PracticeService,
    private readonly customScheduleService: CustomScheduleService,
    private readonly themeCustomizerService: ThemeCustomizerService
  ) {
    super();
  }

  @HostBinding('class.header--is-mobile') get headerIsMobile(): boolean {
    return this.isMobile();
  }

  getPracticeAddressLength(practiceAddress): number {
    return practiceAddress ? practiceAddress.length : 0;
  }
}
