import { Injectable } from '@angular/core';
import { ScriptsService } from './scripts.service';
import { Gtag } from '../models/google-analytics.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  tags = {};
  gtag: Gtag = null;

  constructor(private scriptService: ScriptsService) {}

  public trackEvent(event: string, properties: { [key: string]: any }): void {
    if (!this.gtag) {
      return;
    }
    this.gtag('event', event, properties);
  }

  public initialize(googleAnalyticsId: string): void {
    this.gtag = this.getGoogleTag(googleAnalyticsId);
  }

  private getGoogleTag(id: string): Gtag {
    if (!window) return () => {};
    if (this.tags[id]) return this.tags[id].gtag;

    const layer = 'dataLayer';
    window[layer] = window[layer] || [];
    const gtag = (): void => {
      // DO NOT CHANGE
      // For whatever reason, this does not trigger the event logging if you use `...args`.
      window[layer].push(arguments); // eslint-disable-line prefer-rest-params
    };
    this.tags[id] = { layer, gtag };
    this.scriptService
      .load(`https://www.googletagmanager.com/gtag/js?id=${id}`)
      .subscribe({
        next: () => {
          //@ts-ignore
          gtag('js', new Date());
          //@ts-ignore
          gtag('config', id);
        },
        error: (error) => {
          console.warn(error);
        },
      });
    return gtag;
  }
}
