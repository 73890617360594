export interface InsuranceCompany {
  id: number;
  name?: string;
  is_supported?: boolean;
  is_not_accepted?: boolean;
  practice_id?: number;
}

export interface InsuranceCompanyItem extends InsuranceCompany {
  selected: boolean;
  type: InsuranceCompanyItemType;
}

export enum InsuranceCompanyItemType {
  OTHER = 'other',
  MYSELF = 'myself',
  DEFAULT = 'default',
}
