import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ErrorRedirectReason } from '../../common/models/error-redirect-reason.model';
import { readPhone } from '../../common/utils/string-utils.helper';
import { ScheduleService } from '../schedule.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  readonly title: string;
  readonly description: string;
  readonly practicePhone: string;

  private readonly messages: Record<
    ErrorRedirectReason,
    { title: string; description: string }
  >;

  constructor(
    private readonly scheduleService: ScheduleService,
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {
    this.scheduleService.initialized$.next(true);
    const navState = this.router.getCurrentNavigation().extras.state;
    const error: ErrorRedirectReason =
      navState?.reason || ErrorRedirectReason.PRACTICE_NOT_FOUND;
    this.practicePhone = readPhone(navState?.practicePhone);
    const messageTitle = navState?.title;

    this.messages = {
      [ErrorRedirectReason.PRACTICE_NOT_FOUND]: {
        title: this.translateService.instant('ERROR.PAGE_NOT_FOUND.TITLE'),
        description: this.translateService.instant(
          'ERROR.PAGE_NOT_FOUND.DESCRIPTION'
        ),
      },
      [ErrorRedirectReason.INCORRECT_LINK]: {
        title:
          messageTitle ||
          this.translateService.instant('ERROR.INCORRECT_LINK.TITLE'),
        description: this.translateService.instant(
          'ERROR.INCORRECT_LINK.DESCRIPTION'
        ),
      },
      [ErrorRedirectReason.LINK_EXPIRED]: {
        title: messageTitle,
        description: this.translateService.instant(
          'ERROR.LINK_EXPIRED.DESCRIPTION'
        ),
      },
      [ErrorRedirectReason.LINK_ALREADY_USED]: {
        title: messageTitle,
        description: this.translateService.instant(
          'ERROR.LINK_ALREADY_USED.DESCRIPTION'
        ),
      },
    };

    const messages = this.messages[error];
    this.title = messages.title;
    this.description = messages.description;
  }
}
