import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../providers/window';
import { DOCUMENT } from '@angular/common';
import { Gtag } from '../models/google-analytics.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
    @Inject(WINDOW) public readonly windowRef: Window,
    @Inject(DOCUMENT) private readonly documentRef: Document
  ) {}

  initialize(tagId: string): void {
    this.loadGoogleTag(tagId);
  }

  trackEvent(event: string, properties: unknown): void {
    const gtag = (this.windowRef as Window & { gtag: Gtag }).gtag;

    if (!gtag) {
      return;
    }

    gtag('event', event, properties);
  }

  private loadGoogleTag(id: string): void {
    const loadScript = this.documentRef.createElement('script');
    loadScript.setAttribute('async', 'true');
    loadScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${id}`
    );

    const initScript = this.documentRef.createElement('script');
    initScript.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${id}');`;

    this.documentRef.documentElement.firstChild.appendChild(loadScript);
    this.documentRef.documentElement.firstChild.appendChild(initScript);
  }
}
