import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  THEME_COLOR_CSS_VARIABLES,
  ThemeCustomization,
} from './theme-customizer.types';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ThemeCustomizerService {
  private readonly config$ = new BehaviorSubject<ThemeCustomization>(null);
  private readonly documentRef = inject(DOCUMENT);

  get theme$(): Observable<ThemeCustomization> {
    return this.config$.asObservable();
  }

  initializeCustomStyles(data: ThemeCustomization): void {
    this.config$.next(data);

    this.documentRef.documentElement.style.setProperty(
      THEME_COLOR_CSS_VARIABLES.primaryColor,
      data.primaryColor
    );
    this.documentRef.documentElement.style.setProperty(
      THEME_COLOR_CSS_VARIABLES.backgroundColor,
      data.backgroundColor
    );
  }
}
