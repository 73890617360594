import { Injectable } from '@angular/core';
import { ConfigurableScheduleOptions } from '../common/models/schedule-data.model';
import { Practice } from '../common/practice';
import { IProvider } from '../data-access/provider/provider.model';
import {
  IServiceType,
  ServiceTypeCollection,
} from '../data-access/service-type/service-type.model';
import { ScheduleLinkMapPayload } from './schedule-link.types';

@Injectable({
  providedIn: 'root',
})
export class ScheduleLinkService {
  mapScheduleLinkData<T extends ScheduleLinkMapPayload>(
    customData: T
  ): ConfigurableScheduleOptions {
    const availableInsuranceCompanies = customData.insurance_companies.reduce(
      (acc, { practice_insurance_companies }) => [
        ...acc,
        ...practice_insurance_companies,
      ],
      []
    );

    const practices = (customData?.practices || []).map(
      ({ practice_id, ...rest }) => ({
        id: practice_id,
        ...rest,
        insurance_companies: availableInsuranceCompanies.filter(
          (company) => company.practice_id === practice_id
        ),
      })
    ) as Practice[];

    const serviceTypes =
      practices.length === 1
        ? (customData?.service_types || []).map(
            ({ service_type_id, ...rest }) => ({
              id: service_type_id,
              group: null,
              ...rest,
            })
          )
        : ((customData?.group_service_types || []).map(
            ({ group_service_type_id, service_types, ...rest }) => ({
              id: group_service_type_id,
              group: service_types.map(
                ({
                  service_type_id,
                  name,
                  practice_id,
                  providers,
                  patient_status,
                  patient_type,
                  reservation_fee_dollars,
                }) =>
                  ({
                    id: service_type_id,
                    name,
                    practice_id,
                    patient_status,
                    patient_type,
                    reservation_fee_dollars,
                    providers: (providers || []).map(
                      ({ provider_id, ...restProvider }) => ({
                        id: provider_id,
                        ...restProvider,
                      })
                    ),
                  } as ServiceTypeCollection)
              ),
              ...rest,
            })
          ) as IServiceType[]);

    const providersData = (customData?.providers || []).map(
      ({ provider_id, order, ...rest }) => ({ id: provider_id, ...rest })
    ) as IProvider[];

    return {
      practices,
      serviceTypes,
      providers: providersData,
    };
  }
}
