import { InsuranceCompanyItem } from './insurance-company';

export interface InsuranceSettings {
  insuranceCompanies: InsuranceCompanyItem[];
  payMyself: boolean;
  other: boolean;
  otherNote?: string;
  memberId?: string;
  groupNumber?: string;
}
