import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorRedirectReason } from '../common/models/error-redirect-reason.model';
import {
  ConfigurableScheduleData,
  ScheduleFlow,
} from '../common/models/schedule-data.model';
import { CustomScheduleService } from '../data-access/custom-schedule/custom-schedule.service';
import { ScheduleStepsService } from '../main/schedule-steps/schedule-steps.service';
import { ScheduleService } from '../main/schedule.service';
import { SpinnerService } from '../common/spinner/spinner.service';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';
import { CustomScheduleLink } from '../data-access/custom-schedule/custom-schedule.model';
import { GoogleAnalyticsEventsService } from '../common/services/google-analytics-events.service';
import { ThemeCustomizerService } from '../common/services/theme-customizer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomLinkGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly spinnerService: SpinnerService,
    private readonly scheduleService: ScheduleService,
    private readonly scheduleStepsService: ScheduleStepsService,
    private readonly customScheduleService: CustomScheduleService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private readonly themeCustomizerService: ThemeCustomizerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { uniqueHash, linkName } = route.params as {
      uniqueHash: string;
      linkName: string;
    };

    const { from_widget = null, mode = null } = route.queryParams as {
      from_widget?: string;
      mode?: string;
    };

    if (!uniqueHash || !linkName) {
      this.router.navigate(['page-not-found'], {
        state: { reason: ErrorRedirectReason.INCORRECT_LINK },
      });
      return of(false);
    }

    this.spinnerService.showSpinner(true);

    return this.customScheduleService
      .decodeCustomScheduleLink(uniqueHash, linkName, from_widget, mode)
      .pipe(
        catchError(() => {
          this.spinnerService.showSpinner(false);

          return of(null);
        }),
        tap((decodedData: CustomScheduleLink | null) => {
          this.themeCustomizerService.initializeCustomStyles({
            primaryColor: decodedData?.primary_color,
            backgroundColor: decodedData?.background_color,
            customLogo: decodedData?.custom_logo,
            customNote: decodedData?.custom_note,
          });
          this.spinnerService.showSpinner(false);
        }),
        map((decodedData: CustomScheduleLink | null) => {
          if (decodedData) {
            const steps = this.scheduleStepsService.buildScheduleSteps([
              { slug: 'patient-details' },
              { slug: 'reason-for-visit' },
              { slug: 'time-slot' },
              { slug: 'summary' },
            ]);
            this.scheduleStepsService.setSteps(steps);
            this.scheduleStepsService.moveToStepById(1);
            const preparedData =
              this.customScheduleService.mapCustomScheduleData(
                decodedData,
                from_widget,
                mode
              );
            this.scheduleService.loadScheduleOptions(
              ScheduleFlow.CUSTOM,
              preparedData
            );

            if (decodedData?.google_analytics_tracking_number) {
              this.googleAnalyticsService.initializeGtag(
                decodedData.google_analytics_tracking_number
              );
              this.trackBeginSchedule(
                ScheduleFlow.CUSTOM,
                preparedData,
                from_widget
              );
            }
            if (decodedData?.os_gtm_tag) {
              this.googleAnalyticsService.initializeGtm(decodedData.os_gtm_tag);
            }

            return true;
          }

          this.router.navigate(['page-not-found'], {
            state: { reason: ErrorRedirectReason.INCORRECT_LINK },
          });
          return false;
        })
      );
  }

  private trackBeginSchedule(
    from: ScheduleFlow,
    configuration: ConfigurableScheduleData,
    fromWidget?: string
  ): void {
    const trackingPayload = {
      from,
      widget: fromWidget ?? null,
      data: configuration ?? null,
    };

    this.googleAnalyticsEventsService.trackBeginSchedule(trackingPayload);
  }
}
