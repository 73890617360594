import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import {
  InsuranceCompany,
  InsuranceCompanyItem,
  InsuranceCompanyItemType,
} from './models';
import { PracticeService } from '../../../common/practice';
import { ScheduleService } from '../../schedule.service';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ScheduleFlow } from '../../../common/models/schedule-data.model';

@Injectable({
  providedIn: 'root',
})
export class InsuranceSelectorService {
  readonly insuranceCompaniesSource$: BehaviorSubject<InsuranceCompanyItem[]> =
    new BehaviorSubject<InsuranceCompanyItem[]>([]);

  readonly shouldAskForInsurance$: Observable<boolean> = this.scheduleService
    .getScheduleFlow()
    .pipe(
      switchMap((scheduleFlow: ScheduleFlow) => {
        switch (scheduleFlow) {
          case ScheduleFlow.INVITE:
            return of(false);
          case ScheduleFlow.NEW:
            return this.practiceService
              .getPractice()
              .pipe(map(({ insurance_companies }) => !!insurance_companies));
          case ScheduleFlow.CUSTOM:
            return this.practiceService.getAllPractices().pipe(
              map((practices) =>
                practices.every(({ insurance_companies = null }) => {
                  return !!insurance_companies;
                })
              )
            );
        }
      }),
      take(1)
    );

  readonly shouldShowIsSupported$: Observable<boolean> =
    this.insuranceCompaniesSource$.pipe(
      map((insuranceCompanies) =>
        insuranceCompanies.every((insuranceCompany) =>
          insuranceCompany.hasOwnProperty('is_supported')
        )
      ),
      withLatestFrom(
        this.shouldAskForInsurance$,
        of(this.scheduleService.getScheduleData()).pipe(
          map(({ patient: { isInsuranceChanged } }) =>
            [undefined, true].includes(isInsuranceChanged)
          )
        )
      ),
      map((conditions) => !conditions.includes(false))
    );

  readonly shouldShowIsSupportedForMulti$: Observable<{
    [key: string]: boolean;
  }> = combineLatest([
    this.shouldAskForInsurance$,
    of(this.scheduleService.getScheduleData()).pipe(
      map(({ patient: { isInsuranceChanged } }) =>
        [undefined, true].includes(isInsuranceChanged)
      )
    ),
  ]).pipe(
    map((conditions) => !conditions.includes(false)),
    switchMap((shouldShow) => {
      return this.practiceService.getAllPractices().pipe(
        map((practices) => {
          return practices.reduce((acc, currPractice) => {
            return {
              ...acc,
              [currPractice.guid]: shouldShow
                ? this.insuranceCompaniesHasIsSupportedFlag(
                    currPractice.insurance_companies
                  )
                : false,
            };
          }, {});
        })
      );
    }),
    take(1)
  );

  private readonly initializeSourceBasedOnScheduleFlow$ =
    this.shouldAskForInsurance$
      .pipe(
        filter((shouldAskForInsurance) => shouldAskForInsurance),
        switchMap(() => this.scheduleService.getScheduleFlow()),
        switchMap((scheduleFlow: ScheduleFlow) => {
          return iif(
            () => scheduleFlow === ScheduleFlow.NEW,
            this.practiceService.getPractice(),
            this.practiceService.getAllPractices().pipe(
              map((practices) =>
                practices.find(({ insurance_companies }) => {
                  return insurance_companies?.length > 0;
                })
              )
            )
          );
        }),
        map((practice) => practice?.insurance_companies || []),
        take(1),
        tap((insuranceCompaniesSource) =>
          this.setInsuranceCompaniesSource(insuranceCompaniesSource)
        )
      )
      .subscribe();

  constructor(
    private practiceService: PracticeService,
    private scheduleService: ScheduleService
  ) {}

  setInsuranceCompaniesSource(source: InsuranceCompany[] = []): void {
    this.insuranceCompaniesSource$.next(
      source.map((insuranceCompany) => ({
        ...insuranceCompany,
        selected: false,
        type: InsuranceCompanyItemType.DEFAULT,
      }))
    );
  }

  updateInsuranceCompaniesSource(
    updateLogicFn: (item: InsuranceCompanyItem) => InsuranceCompanyItem
  ): void {
    this.insuranceCompaniesSource$.next([
      ...this.insuranceCompaniesSource$
        .getValue()
        .map((item) => updateLogicFn(item)),
    ]);
  }

  private insuranceCompaniesHasIsSupportedFlag(
    insuranceCompanies: InsuranceCompany[]
  ): boolean {
    return (
      !!insuranceCompanies &&
      insuranceCompanies.length > 0 &&
      !insuranceCompanies.some(
        (insuranceCompany: InsuranceCompany) =>
          !insuranceCompany.hasOwnProperty('is_supported')
      )
    );
  }
}
