export interface ThemeCustomization {
  primaryColor: string;
  backgroundColor: string;
  customLogo?: string;
  customNote?: string;
}

type ThemeCustomizationColorType = keyof Pick<
  ThemeCustomization,
  'primaryColor' | 'backgroundColor'
>;

export const THEME_COLOR_CSS_VARIABLES: Record<
  ThemeCustomizationColorType,
  string
> = {
  primaryColor: '--di-book-custom-primary-color',
  backgroundColor: '--di-book-custom-background-color',
};
