import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { ScheduleStepsService } from '../main/schedule-steps/schedule-steps.service';
import { ReservationFeeParam } from '../main/success-page/models/success-page-params';
import { ScheduleService } from '../main/schedule.service';

@Injectable({
  providedIn: 'root',
})
export class FirstFormVisitGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly scheduleStepsService: ScheduleStepsService,
    private readonly scheduleService: ScheduleService
  ) {}

  get firstStep(): string {
    return this.scheduleStepsService.firstAvailableStep.command;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    this.scheduleService.initialized$.next(true);
    return this.redirectOrAllowRoute(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.redirectOrAllowRoute(route);
  }

  private redirectOrAllowRoute(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree {
    return this.shouldNavigateToFirstStep(route)
      ? this.router.createUrlTree(this.buildRelativePath(route), {
          queryParams: route.queryParams,
        })
      : true;
  }

  private buildRelativePath({
    pathFromRoot,
  }: ActivatedRouteSnapshot): string[] {
    const urlSegments = pathFromRoot
      .map((snapshot) => snapshot.url)
      .reduce((acc, segments) => acc.concat(segments), [])
      .filter((urlSegment) => !!urlSegment);

    return [
      ...urlSegments.map(({ path }) => path).slice(0, -1),
      this.firstStep,
    ];
  }

  private shouldNavigateToFirstStep(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParams?.reservation_fee === ReservationFeeParam.PAID) {
      return false;
    }

    return this.isFirstVisit() && this.isNotFirstStep(route);
  }

  private isFirstVisit(): boolean {
    return !this.router.navigated;
  }

  private isNotFirstStep(route: ActivatedRouteSnapshot): boolean {
    const path = route?.routeConfig?.path ?? null;

    if (path === null) {
      return true;
    }

    if (path === '') {
      return false;
    }

    return path !== this.firstStep;
  }
}
