import { Inject, Injectable } from '@angular/core';
import { Materialize } from '../../materialize';
import { ErrorMessageService } from './error-message.service';
import { DOCUMENT } from '@angular/common';

declare const M: Materialize;

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private readonly DISPLAY_TIME = 10_000;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly errorMessageService: ErrorMessageService
  ) {}

  public showError(message: string): void {
    M.toast({
      html: this.convertMessageToHtml(message),
      classes: 'error',
      displayLength: this.DISPLAY_TIME,
    });
  }

  public showInfo(message: string): void {
    M.toast({
      html: this.convertMessageToHtml(message),
      classes: 'info',
      displayLength: this.DISPLAY_TIME,
    });
  }

  public showApiError(error): void {
    this.showError(this.errorMessageService.getErrorMessage(error));
  }

  private convertMessageToHtml(message: string): string {
    return (
      '<span tabindex="0" role="alert" id="toast-message">' +
      message +
      '</span>'
    );
  }
}
