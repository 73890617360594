export interface ThemeCustomization {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  customLogo?: string;
  customNote?: string;
}

interface LocalThemeCustomization {
  shadowSecondaryColor: string;
  shadowPrimaryColor: string;
}

type ThemeCustomizationColorType = keyof Pick<
  ThemeCustomization & LocalThemeCustomization,
  | 'primaryColor'
  | 'secondaryColor'
  | 'backgroundColor'
  | 'shadowSecondaryColor'
  | 'shadowPrimaryColor'
>;

export const THEME_COLOR_CSS_VARIABLES: Record<
  ThemeCustomizationColorType,
  string
> = {
  primaryColor: '--di-book-custom-primary-color',
  secondaryColor: '--di-book-custom-secondary-color',
  backgroundColor: '--di-book-custom-background-color',
  shadowPrimaryColor: '--di-book-shadow-primary-color',
  shadowSecondaryColor: '--di-book-shadow-secondary-color',
};
