import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorRedirectReason } from '../common/models/error-redirect-reason.model';
import { ScheduleFlow } from '../common/models/schedule-data.model';
import { CustomScheduleService } from '../data-access/custom-schedule/custom-schedule.service';
import { ScheduleStepsService } from '../main/schedule-steps/schedule-steps.service';
import { ScheduleService } from '../main/schedule.service';
import { GoogleAnalyticsService } from '../common/services/googleAnalytics.service';

@Injectable({
  providedIn: 'root',
})
export class CustomLinkGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly scheduleService: ScheduleService,
    private readonly scheduleStepsService: ScheduleStepsService,
    private readonly customScheduleService: CustomScheduleService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { uniqueHash, linkName } = route.params as {
      uniqueHash: string;
      linkName: string;
    };

    const { from_widget = null, mode = null } = route.queryParams as {
      from_widget?: string;
      mode?: string;
    };

    if (!uniqueHash || !linkName) {
      this.router.navigate(['page-not-found'], {
        state: { reason: ErrorRedirectReason.INCORRECT_LINK },
      });
      return of(false);
    }

    return this.customScheduleService
      .decodeCustomScheduleLink(uniqueHash, linkName, from_widget, mode)
      .pipe(
        catchError(() => of(null)),
        map((decodedData) => {
          if (decodedData) {
            const steps = this.scheduleStepsService.buildScheduleSteps([
              { slug: 'patient-details' },
              { slug: 'reason-for-visit' },
              { slug: 'time-slot' },
              { slug: 'summary' },
            ]);
            this.scheduleStepsService.setSteps(steps);
            this.scheduleStepsService.moveToStepById(1);
            const preparedData =
              this.customScheduleService.mapCustomScheduleData(
                decodedData,
                from_widget,
                mode
              );
            this.scheduleService.loadScheduleOptions(
              ScheduleFlow.CUSTOM,
              preparedData
            );
            if (decodedData.widget?.google_analytics_tracking_number) {
              // this.googleAnalyticsService.initialize(
              //   decodedData.widget?.google_analytics_tracking_number
              // );
            }
            return true;
          }

          this.router.navigate(['page-not-found'], {
            state: { reason: ErrorRedirectReason.INCORRECT_LINK },
          });
          return false;
        })
      );
  }
}
